import { useState } from "react";
import "./home.css";
import Intro from "../../images/connection.svg";
import PulmonicConsonants from "../consonants/pulmonicConsonants";
import Vowels from "../vowels/vowels";

import Dropdown from "../dropdown/dropdown";
import "../dropdown/dropdown.css";

const Home = () => {
  const [isSelected, setIsSelected] = useState("Hindi");

  return (
    <div className="app">
      <div className="app-intro-container">
        <img className="app-intro-img" src={Intro} alt="intro"></img>
        <div className="app-intro-content">
          <h1>StrangeLingo</h1>
          <p>Connecting people</p>
        </div>
      </div>
      <Dropdown isSelected={isSelected} setIsSelected={setIsSelected} />
      <PulmonicConsonants dataFileName={isSelected} />
      <Vowels dataFileName={isSelected} />
    </div>
  );
};

export default Home;
