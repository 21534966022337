const Gujarati = {
  Plosive: {
    Bilabial: {
      voiceless: "P p", 
      voicelessNative: "પ",
      voiced: "B b",
      voicedNative: "બ"
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Dental: {
      voiceless: "ⵙ",
      voicelessNative: "ત",
      voiced: "ⵙ",
      voicedNative: "દ"
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Retroflex: {
      voiceless: "T t",
      voicelessNative: "ટ",
      voiced: "D d",
      voicedNative: "ડ"
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Velar: {
      voiceless: "K k",
      voicelessNative: "ક",
      voiced: "G g",
      voicedNative: "ગ"
    },
    Uvular: {
      voiceless: "ⵙ",
      voicelessNative: "NA",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    }
  },

  Aspirated: {
    Bilabial: {
      voiceless: "ⵙ",
      voicelessNative: "ફ",
      voiced: "ⵙ",
      voicedNative: "ભ"
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Dental: {
      voiceless: "ⵙ",
      voicelessNative: "થ",
      voiced: "ⵙ",
      voicedNative: "ધ"
    },
    Alveolar: {
      voiceless: "ⵙ",
      voicelessNative: "છ",
      voiced: "",
      voicedNative: ""
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "ⵙ",
      voicedNative: "ઝ"
    },
    Retroflex: {
      voiceless: "ⵙ",
      voicelessNative: "ઠ",
      voiced: "ⵙ",
      voicedNative: "ઢ"
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Velar: {
      voiceless: "ⵙ",
      voicelessNative: "ખ",
      voiced: "ⵙ",
      voicedNative: "ઘ"
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    }
  },

  Affricates: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Alveolar: {
      voiceless: "C c",
      voicelessNative: "ચ",
      voiced: "",
      voicedNative: ""
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "J j",
      voicedNative: "જ"
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    }
  },

  Fricatives: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Labiodental: {
      voiceless: "F f",
      voicelessNative: "NA",
      voiced: "W w",
      voicedNative: ""
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Alveolar: {
      voiceless: "S s",
      voicelessNative: "સ",
      voiced: "Z z",
      voicedNative: "ઝ"
    },
    Postalveolar: {
      voiceless: "ⵙ",
      voicelessNative: "શ",
      voiced: "ⵙ",
      voicedNative: "NA"
    },
    Retroflex: {
      voiceless: "X x",
      voicelessNative: "ષ",
      voiced: "",
      voicedNative: ""
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Velar: {
      voiceless: "ⵙ",
      voicelessNative: "NA",
      voiced: "ⵙ",
      voicedNative: "NA"
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "ⵙ",
      voicelessNative: "NA",
      voiced: "H h",
      voicedNative: "હ"
    }
  },

  Nasal: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "M m",
      voicedNative: "મ"
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "N n",
      voicedNative: "ન"
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "ⵙ",
      voicedNative: "ણ"
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "ⵙ",
      voicedNative: "જે"
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "ⵙ",
      voicedNative: "NA"
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    }
  },

  Trill: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "R r",
      voicedNative: "ર"
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    }
  },

  "Tap or Flap": {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "ⵙ",
      voicedNative: "NA"
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    }
  },

  Approximant: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "V v",
      voicedNative: "વ"
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "Y y",
      voicedNative: "ય"
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    }
  },

  "Lateral Approx": {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "L l",
      voicedNative: "લ"
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "ⵙ",
      voicedNative: "ળ"
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    }
  },

  "Lateral Fricative": {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: ""
    }
  }
};

export default Gujarati;
