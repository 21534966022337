import "css-doodle";
import "./about.css";

const About = () => (
  <div className="app-about">
    <css-doodle class="try">
      {`
            :doodle 
            { 
                @grid: 10 / 100%;
                background: rgb(108,99,255);
                cursor: pointer;
                --s : 1;
            }

            // :doodle(:hover) 
            // { 
            //     --s: 0; 
            // }

            :after
            {
                content: @pick-d([A-Za-z]);
                font-size: 4vmax;
                color: #fff;
                transform: scale(@rand(.2, .9));
            }

            transition: .5s cubic-bezier(.175, .885, .32, 1.275);
            transition-delay: @rand(650ms);
            transform: translateY(calc(var(--s) * 100%)) rotate(calc(var(--s) * 360deg));
            
        `}
    </css-doodle>
    <div className="app-about-container">
      <h2>The Project</h2>
      <p>
        Communication is the key to everything. Let’s not make alphabets a
        barrier to communication. How about an alphabet which is known to
        everyone no matter their language. We are working on a similar project.
      </p>
    </div>
  </div>
);

export default About;
