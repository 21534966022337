import "./pulmonicConsonants.css";
import Consonants from "./consonants";
function PulmonicConsonants(props) {
  return (
    <>
      <h2>Consonants</h2>
      <div className="app-consonants-container">
        <>
          <div className="app-consonants-card">
            <div>
              <p>
                <span style={{ textDecorationLine: "underline" }}>Place</span>{" "}
                of Articulation
              </p>
              <p>
                (Position of Tongue/Lips/Teeth in the mouth while producing the
                sounds.)
              </p>
            </div>

            <div className="app-consonants-card-child">
              <div className="vertical-p">
                <p>
                  <span style={{ textDecorationLine: "underline" }}>
                    Manner
                  </span>{" "}
                  of Articulation
                </p>
                <p>(How Air Flows and Releases from the mouth.)</p>
              </div>
              <div>
                <Consonants dataFileName={props.dataFileName} />
              </div>
            </div>

            <div>
              <p>ⵙ : Yet to be symbolized</p>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default PulmonicConsonants;
