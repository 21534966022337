import VowelAlphabetCard from "./vowelAlphacard";
import "./vowels.css";

function VowelRow(props) {
  const columnKeys = Object.keys(props.rowData);

  return (
    <tr className="app-vowels-table-tbody-tr">
      <th>{props.rowKey}</th>
      {columnKeys.map((columnKey) => {
        const columnData = props.rowData[columnKey];
        const uidUnrouded = props.rowKey + "-" + columnKey + "unrounded";
        const uidRounded = props.rowKey + "-" + columnKey + "rounded";
        let td = <td key={columnKey}></td>;

        if (columnData.unroundedCaps && columnData.roundedCaps) {
          td = (
            <td key={columnKey}>
              <div className="vowelcard-container">
                <VowelAlphabetCard
                  key={uidUnrouded}
                  characterCaps={columnData.unroundedCaps}
                  characterSm={columnData.unroundedSm}
                  diaCharacter={columnData.unroundedDia}
                  nativeCharacter={columnData.unroundedNative}
                  nativeDiaCharacter={columnData.unroundedNativeDia}
                />
                <VowelAlphabetCard
                  key={uidRounded}
                  characterCaps={columnData.roundedCaps}
                  characterSm={columnData.roundedSm}
                  diaCharacter={columnData.roundedDia}
                  nativeCharacter={columnData.roundedNative}
                  nativeDiaCharacter={columnData.roundedNativeDia}
                />
              </div>
            </td>
          );
        } else if (columnData.unroundedCaps) {
          td = (
            <td key={columnKey}>
              <div
                className={`vowelcard-container ${
                  columnData.alignLeft
                    ? "justify-left"
                    : columnData.alignRight
                    ? "justify-right"
                    : ""
                }`}
              >
                <VowelAlphabetCard
                  key={uidUnrouded}
                  characterCaps={columnData.unroundedCaps}
                  characterSm={columnData.unroundedSm}
                  diaCharacter={columnData.unroundedDia}
                  nativeCharacter={columnData.unroundedNative}
                  nativeDiaCharacter={columnData.unroundedNativeDia}
                  alignMiddle="alignMiddle"
                />
              </div>
            </td>
          );
        } else if (columnData.roundedCaps) {
          td = (
            <td key={columnKey}>
              <div
                className={`vowelcard-container ${
                  columnData.alignLeft
                    ? "justify-left"
                    : columnData.alignRight
                    ? "justify-right"
                    : ""
                }`}
              >
                <VowelAlphabetCard
                  key={uidRounded}
                  characterCaps={columnData.roundedCaps}
                  characterSm={columnData.roundedSm}
                  diaCharacter={columnData.roundedDia}
                  nativeCharacter={columnData.roundedNative}
                  nativeDiaCharacter={columnData.roundedNativeDia}
                  alignMiddle="alignMiddle"
                />
              </div>
            </td>
          );
        }

        return td;
      })}
    </tr>
  );
}

export default VowelRow;
