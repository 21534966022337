import React from "react";
import AlphabetCard from "./alphacard";
import "./consonants.css";

function Row(props) {
  const columnKeys = Object.keys(props.rowData);

  return (
    <tr className="app-consonants-table-tbody-tr">
      <th>{props.rowKey}</th>
      {columnKeys.map((columnKey) => {
        const columnData = props.rowData[columnKey];
        const uid = props.rowKey + "-" + columnKey;
        let td = <td key={columnKey}></td>;

        if (
          columnData.voiceless ||
          columnData.voiced ||
          columnData.voicelessNative ||
          columnData.voicedNative
        ) {
          td = (
            <td key={columnKey}>
              <AlphabetCard
                key={uid}
                voiceless={columnData.voiceless}
                voicelessNative={columnData.voicelessNative}
                voiced={columnData.voiced}
                voiceledNative={columnData.voicedNative}
              />
            </td>
          );
        }

        return td;
      })}
    </tr>
  );
}

export default Row;
