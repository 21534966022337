import "./footer.css";

const Footer = () => {
  const year =  new Date().getFullYear();

  return (
    <footer className="app-footer">
      <p>Copyright @ StrangeLingo LLP {year}</p>
    </footer>
  );
}

export default Footer;
