import { useState } from "react";
import "./dropdown.css";

function Dropdown({ isSelected, setIsSelected }) {
  const [isActive, setIsActive] = useState(false);
  const options = ["Hindi", "Gujarati"];

  return (
    <>
      <div className="dropdown">
      🌎
        <div className="dropdown-btn" onClick={(e) => setIsActive(!isActive)}>
          <>{isSelected}</>
        </div>
        {isActive && (
          <div className="dropdown-content">
            {options.map((option) => (
              <div
                key={option}
                onClick={(e) => {
                  setIsSelected(option);
                  setIsActive(false);
                }}
                className="dropdown-item"
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Dropdown;
