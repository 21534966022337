const vowelsdata = {
  Close: {
    Front: {
      unroundedCaps: "0xF111",
      unroundedSm: "0xF112",
      unroundedDia: "0xF113",
      unroundedNative: "ई",
      unroundedNativeDia: "ी",
      roundedCaps: "0xF121",
      roundedSm: "0xF122",
      roundedDia: "0xF123",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Front": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Central: {
      unroundedCaps: "0xF151",
      unroundedSm: "0xF152",
      unroundedDia: "0xF153",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "0xF161",
      roundedSm: "0xF162",
      roundedDia: "0xF163",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Back": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Back: {
      unroundedCaps: "0xF191",
      unroundedSm: "0xF192",
      unroundedDia: "0xF193",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "0xF1A1",
      roundedSm: "0xF1A2",
      roundedDia: "0xF1A3",
      roundedNative: "ऊ",
      roundedNativeDia: "ू",
      alignLeft: "",
      alignRight: "",
    },
  },

  "Near Close": {
    Front: {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Front": {
      unroundedCaps: "0xF231",
      unroundedSm: "0xF232",
      unroundedDia: "0xF233",
      unroundedNative: "इ",
      unroundedNativeDia: "ि",
      roundedCaps: "0xF241",
      roundedSm: "0xF242",
      roundedDia: "0xF243",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Central: {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Back": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "0xF281",
      roundedSm: "0xF282",
      roundedDia: "0xF283",
      roundedNative: "उ",
      roundedNativeDia: "ु",
      alignLeft: "",
      alignRight: "right",
    },
    Back: {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
  },

  "Close Mid": {
    Front: {
      unroundedCaps: "0xF311",
      unroundedSm: "0xF312",
      unroundedDia: "0xF313",
      unroundedNative: "ए",
      unroundedNativeDia: "े",
      roundedCaps: "0xF321",
      roundedSm: "0xF322",
      roundedDia: "0xF323",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Front": {
      unroundedCaps: "",
      unroundedSm: "",
      unoundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Central: {
      unroundedCaps: "0xF351",
      unroundedSm: "0xF352",
      unroundedDia: "0xF353",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "0xF361",
      roundedSm: "0xF362",
      roundedDia: "0xF363",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Back": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Back: {
      unroundedCaps: "0xF391",
      unroundedSm: "0xF392",
      unroundedDia: "0xF393",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "0xF3A1",
      roundedSm: "0xF3A2",
      roundedDia: "0xF3A3",
      roundedNative: "ओ",
      roundedNativeDia: "ो",
      alignLeft: "",
      alignRight: "",
    },
  },

  Mid: {
    Front: {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Front": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Central: {
      unroundedCaps: "0xF451",
      unroundedSm: "0xF452",
      unroundedDia: "0xF453",
      unroundedNative: "अ",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Back": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Back: {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
  },

  "Open Mid": {
    Front: {
      unroundedCaps: "0xF511",
      unroundedSm: "0xF512",
      unroundedDia: "0xF513",
      unroundedNative: "ऐ",
      unroundedNativeDia: "ै",
      roundedCaps: "0xF521",
      roundedSm: "0xF522",
      roundedDia: "0xF523",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Front": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Central: {
      unroundedCaps: "0xF551",
      unroundedSm: "0xF552",
      unroundedDia: "0xF553",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "0xF561",
      roundedSm: "0xF562",
      roundedDia: "0xF563",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Back": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Back: {
      unroundedCaps: "0xF591",
      unroundedSm: "0xF592",
      unroundedDia: "0xF593",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "0xF5A1",
      roundedSm: "0xF5A2",
      roundedDia: "0xF5A3",
      roundedNative: "औ",
      roundedNativeDia: "ौ",
      alignLeft: "",
      alignRight: "",
    },
  },

  "Near open": {
    Front: {
      unroundedCaps: "0xF611",
      unroundedSm: "0xF612",
      unroundedDia: "0xF613",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "left",
      alignRight: "",
    },
    "Near Front": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Central: {
      unroundedCaps: "0xF651",
      unroundedSm: "0xF652",
      unroundedDia: "0xF653",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Back": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Back: {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
  },

  Open: {
    Front: {
      unroundedCaps: "0xF711",
      unroundedSm: "0xF712",
      unroundedDia: "0xF713",
      unroundedNative: "आ",
      unroundedNativeDia: "ा",
      roundedCaps: "0xF721",
      roundedSm: "0xF722",
      roundedDia: "0xF723",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Front": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Central: {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    "Near Back": {
      unroundedCaps: "",
      unroundedSm: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "",
      roundedSm: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
    Back: {
      unroundedCaps: "0xF791",
      unroundedSm: "0xF792",
      unroundedDia: "0xF793",
      unroundedNative: "",
      unroundedNativeDia: "",
      roundedCaps: "0xF7A1",
      roundedSm: "0xF7A2",
      roundedDia: "0xF7A3",
      roundedNative: "",
      roundedNativeDia: "",
      alignLeft: "",
      alignRight: "",
    },
  },
};

export default vowelsdata;
