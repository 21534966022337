import "./header.css";
import logo from "../../logo.png";

const Header = () => (
    <header className="app-header">
      <div className="app-logo-container">
        <img src={logo} className="app-logo" alt="logo" />
        <a href="/" className="app-logo-text">
          StrangeLingo
        </a>
      </div>

      <ul className="app-menu">
        <li>
          <a href="/home" className="app-links">
            Home
          </a>
        </li>
        <li>
          <a href="/services" className="app-links">
            Services
          </a>
        </li>
        <li>
          <a href="/about" className="app-links">
            About
          </a>
        </li>
        <li>
          <a href="/contact" className="app-links">
            Contact
          </a>
        </li>
      </ul>
    </header>
);

export default Header;
