const vowelsdata = {
  Close: {
    Front: {
      unrounded: "0xF111 0xF112",
      unroundedDia: "0xF113",
      unroundedNative: "ઇ",
      unroundedNativeDia:"0xF115",
      rounded: "y",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Front": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Central: {
      unrounded: "ɨ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "ʉ",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Back": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Back: {
      unrounded: "ɯ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "u",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
  },

  "Near Close": {
    Front: {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Front": {
      unrounded: "ɪ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "ʏ",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Central: {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Back": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "ʊ",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Back: {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
  },

  "Close Mid": {
    Front: {
      unrounded: "e",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "ø",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Front": {
      unrounded: "",
      unoundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Central: {
      unrounded: "ɘ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "ɵ",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Back": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Back: {
      unrounded: "ɤ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "o",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
  },

  Mid: {
    Front: {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Front": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Central: {
      unrounded: "ə",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Back": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Back: {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
  },

  "Open Mid": {
    Front: {
      unrounded: "ɛ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "œ",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Front": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Central: {
      unrounded: "ɜ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "ɞ",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Back": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Back: {
      unrounded: "ʌ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "ɔ",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
  },

  "Near open": {
    Front: {
      unrounded: "æ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Front": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Central: {
      unrounded: "ɐ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Back": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Back: {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
  },

  Open: {
    Front: {
      unrounded: "a",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "ɶ",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Front": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Central: {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    "Near Back": {
      unrounded: "",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
    Back: {
      unrounded: "ɑ",
      unroundedDia: "",
      unroundedNative: "",
      unroundedNativeDia:"",
      rounded: "ɒ",
      roundedDia: "",
      roundedNative: "",
      roundedNativeDia:"",
    },
  },
};

export default vowelsdata;
