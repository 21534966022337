import "./vowelAlphacard.css";

function VowelAlphabetCard(props) {
  let characterCaps;
  let characterSm;
  let diaCharacter;
  let nativeCharacter;
  let nativeDiaCharacter;

  //code
  if (props.characterCaps.startsWith("0x")) {
    characterCaps = String.fromCharCode(props.characterCaps);
  } else {
    characterCaps = props.characterCaps;
  }

  if (props.characterSm.startsWith("0x")) {
    characterSm = String.fromCharCode(props.characterSm);
  } else {
    characterSm = props.characterSm;
  }

  if (props.diaCharacter.startsWith("0x")) {
    diaCharacter = String.fromCharCode(props.diaCharacter);
  } else {
    diaCharacter = props.diaCharacter;
  }

  if (props.nativeCharacter.startsWith("0x")) {
    nativeCharacter = String.fromCharCode(props.nativeCharacter);
  } else {
    nativeCharacter = props.nativeCharacter;
  }

  if (props.nativeDiaCharacter.startsWith("0x")) {
    nativeDiaCharacter = String.fromCharCode(props.nativeDiaCharacter);
  } else {
    nativeDiaCharacter = props.nativeDiaCharacter;
  }

  return (
    // <div className="vowel-custom-font vowel-child-container">
    <div
      className={`vowel-custom-font vowel-child-container ${
        props.alignMiddle ? "vowelcard-align-middle" : ""
      }`}
    >
      <div className="vowel-child">{characterCaps}</div>
      <div className="vowel-child">{characterSm}</div>
      <div className="vowel-child">{diaCharacter}</div>
      <div className="vowel-child">{nativeCharacter}</div>
      <div className="vowel-child">{nativeDiaCharacter}</div>
    </div>
  );
}

export default VowelAlphabetCard;
