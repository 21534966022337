import VowelRow from "./vowelRow";
import "./vowels.css";
import Hindi from "./vHindi";
import Gujarati from "./vGujarati";

const DataMap = {
  Hindi: Hindi,
  Gujarati: Gujarati,
};

function Vowels(props) {
  const data = DataMap[props.dataFileName];
  const rowKeys = Object.keys(data);
  const columnKeys = Object.keys(data[rowKeys[0]]);
  return (
    <>
      <h2>Vowels</h2>
      <div className="app-vowels-container">
        <>
          <div className="app-vowels-card">
            <table className="app-vowels-table">
              <thead className="app-vowels-table-thead">
                <tr className="app-vowels-table-thead-tr">
                  <th></th>
                  {columnKeys.map((colunmKey) => {
                    return <th key={colunmKey}>{colunmKey}</th>;
                  })}
                </tr>
              </thead>
              <tbody className="app-vowels-table-tbody">
                {rowKeys.map((rowKey) => {
                  const rowData = data[rowKey];
                  return (
                    <VowelRow key={rowKey} rowKey={rowKey} rowData={rowData} />
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      </div>
    </>
  );
}

export default Vowels;
