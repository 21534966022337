import "./contact.css";
import ContactImg from "../../images/contact.svg";

const Contact = () => (
  <div className="app-contact">
    <div className="app-contact-container">
      <div>
        <h2>Get in touch.</h2>
        <p>
          If you want to be part of this project and contribute or just want to
          know more, then get in touch. Mail us at <strong>strangelingo@gmail.com</strong>
        </p>
      </div>
      <img className="app-contact-img" src={ContactImg} alt="contactimg"></img>
    </div>
  </div>
);

export default Contact;
