import "./services.css";

const Services = () => (
  <div className="app-service">
    <div className="app-service-container">
    <h2>Services</h2>
      <p>
        We provide localization services like subtitling, translation, sync
        sorrection and other ancillary services for movies, shows, documentaries in the various indic
        languages.
      </p>
    </div>
    <div className="app-service-pattern"></div>
  </div>
);

export default Services;
