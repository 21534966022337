import "./App.css";
import Header from "./components/header/header";
import Home from "./components/home/home";
import Services from "./components/services/services";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


function App() {
  return (

    <Router>
      <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/services" component={Services} />
        </Switch>
        <Footer />    
    </Router>
  );
}

export default App;
