import "./alphacard.css";
import useSound from "use-sound";
import Sounds from "../soundsImport";

function AlphabetCard(props) {
  let voiceless = props.voiceless;
  let voicelessCharacter;

  let voiced = props.voiced;
  let voicedCharacter;

  let voicelessArray;
  let voicedArray;

  //code
  if (voiceless.startsWith("0x")) {
    voicelessArray = voiceless.split(" ");
    voicelessCharacter = String.fromCharCode(voicelessArray[0])
      .concat(" ")
      .concat(String.fromCharCode(voicelessArray[1]));
  } else {
    voicelessCharacter = voiceless;
  }

  if (voiced.startsWith("0x")) {
    voicedArray = voiced.split(" ");
    voicedCharacter = String.fromCharCode(voicedArray[0])
      .concat(" ")
      .concat(String.fromCharCode(voicedArray[1]));
  } else {
    voicedCharacter = voiced;
  }

  const [PlayVoiceless] = useSound(Sounds[voiceless.split(" ")[0]]);
  const [PlayVoiced] = useSound(Sounds[voiced.split(" ")[0]]);

  return (
    <div className="alphacard-container">
      <div className="alphacard-container-child">
        <div
          className="alphacard-child voiceless custom-font"
          onClick={PlayVoiceless}
        >
          {voicelessCharacter}
        </div>
        <div className="alphacard-child voiceless custom-font">
          {props.voicelessNative}
        </div>
      </div>

      <div className="alphacard-container-child">
        <div
          className="alphacard-child voiced custom-font"
          onClick={PlayVoiced}
        >
          {voicedCharacter}
        </div>
        <div className="alphacard-child voiced custom-font">
          {props.voiceledNative}
        </div>
      </div>
    </div>
  );
}

export default AlphabetCard;
