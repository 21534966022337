import Row from "./row";
import Hindi from "./hindi";
import Gujarati from "./gujarati";
import "./consonants.css";

const DataMap = {
  "Hindi": Hindi,
  "Gujarati": Gujarati
}

function Consonants(props) {
  const data = DataMap[props.dataFileName];
  
  const rowKeys = Object.keys(data);
  const columnKeys = Object.keys(data[rowKeys[0]]);
  return (
    <table className="app-consonants-table">
      <thead className="app-consonants-table-thead">
      <tr>
      <th></th>
        {columnKeys.map((colunmKey) => {
          return <th key={colunmKey}>{colunmKey}</th>;
        })}
      </tr>
        
      </thead>
      <tbody className="app-consonants-table-tbody">
        {rowKeys.map((rowKey) => {
          const rowData = data[rowKey];
          return <Row  key={rowKey} rowKey={rowKey} rowData={rowData} />;
        })}
      </tbody>
    </table>
  );
}

export default Consonants;
