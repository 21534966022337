const Hindi = {
  Plosive: {
    Bilabial: {
      voiceless: "0xE111 0xE112",
      voicelessNative: "प",
      voiced: "0xE113 0xE114",
      voicedNative: "ब",
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Dental: {
      voiceless: "0xE131 0xE132",
      voicelessNative: "त",
      voiced: "0xE133 0xE134",
      voicedNative: "द",
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Retroflex: {
      voiceless: "0xE161 0xE162",
      voicelessNative: "ट",
      voiced: "0xE163 0xE164",
      voicedNative: "ड",
    },
    Palatal: {
      voiceless: "0xE171 0xE172",
      voicelessNative: "",
      voiced: "0xE173 0xE174",
      voicedNative: "",
    },
    Velar: {
      voiceless: "0xE181 0xE182",
      voicelessNative: "क",
      voiced: "0xE183 0xE184",
      voicedNative: "ग",
    },
    Uvular: {
      voiceless: "0xE191 0xE192",
      voicelessNative: "क़",
      voiced: "",
      voicedNative: "",
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Glottal: {
      voiceless: "0xE1B1 0xE1B2",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
  },

  Aspirated: {
    Bilabial: {
      voiceless: "0xE211 0xE212",
      voicelessNative: "फ",
      voiced: "0xE213 0xE214",
      voicedNative: "भ",
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Dental: {
      voiceless: "0xE231 0xE232",
      voicelessNative: "थ",
      voiced: "0xE233 0xE234",
      voicedNative: "ध",
    },
    Alveolar: {
      voiceless: "0xE241 0xE242",
      voicelessNative: "छ",
      voiced: "",
      voicedNative: "",
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE253 0xE254",
      voicedNative: "झ",
    },
    Retroflex: {
      voiceless: "0xE261 0xE262",
      voicelessNative: "ठ",
      voiced: "0xE263 0xE264",
      voicedNative: "ढ",
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Velar: {
      voiceless: "0xE281 0xE282",
      voicelessNative: "ख",
      voiced: "0xE283 0xE284",
      voicedNative: "घ",
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
  },

  Affricates: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Alveolar: {
      voiceless: "0xE341 0xE342",
      voicelessNative: "च",
      voiced: "",
      voicedNative: "",
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE353 0xE354",
      voicedNative: "ज",
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
  },

  Fricative: {
    Bilabial: {
      voiceless: "0xE411 0xE412",
      voicelessNative: "",
      voiced: "0xE413 0xE414",
      voicedNative: "",
    },
    Labiodental: {
      voiceless: "0xE421 0xE422",
      voicelessNative: "फ़",
      voiced: "0xE423 0xE424",
      voicedNative: "",
    },
    Dental: {
      voiceless: "0xE431 0xE432",
      voicelessNative: "",
      voiced: "0xE433 0xE434",
      voicedNative: "",
    },
    Alveolar: {
      voiceless: "0xE441 0xE442",
      voicelessNative: "स",
      voiced: "0xE443 0xE444",
      voicedNative: "ज़",
    },
    Postalveolar: {
      voiceless: "0xE451 0xE452",
      voicelessNative: "श",
      voiced: "0xE453 0xE454",
      voicedNative: "झ़",
    },
    Retroflex: {
      voiceless: "0xE461 0xE462",
      voicelessNative: "ष",
      voiced: "0xE463 0xE464",
      voicedNative: "",
    },
    Palatal: {
      voiceless: "0xE471 0xE472",
      voicelessNative: "",
      voiced: "0xE473 0xE474",
      voicedNative: "",
    },
    Velar: {
      voiceless: "0xE481 0xE482",
      voicelessNative: "ख़",
      voiced: "0xE483 0xE484",
      voicedNative: "ग़",
    },
    Uvular: {
      voiceless: "0xE491 0xE492",
      voicelessNative: "",
      voiced: "0xE493 0xE494",
      voicedNative: "",
    },
    Pharyngeal: {
      voiceless: "0xE4A1 0xE4A2",
      voicelessNative: "",
      voiced: "0xE4A3 0xE4A4",
      voicedNative: "",
    },
    Glottal: {
      voiceless: "0xE4B1 0xE4B2",
      voicelessNative: "अः",
      voiced: "0xE4B3 0xE4B4",
      voicedNative: "ह",
    },
  },

  Nasal: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE513 0xE514",
      voicedNative: "म",
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE523 0xE524",
      voicedNative: "",
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE543 0xE544",
      voicedNative: "न",
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE563 0xE564",
      voicedNative: "ण",
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE573 0xE574",
      voicedNative: "ञ",
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE583 0xE584",
      voicedNative: "ङ",
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE593 0xE594",
      voicedNative: "",
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
  },

  Approx: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE623 0xE624",
      voicedNative: "व",
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE643 0xE644",
      voicedNative: "",
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE663 0xE664",
      voicedNative: "",
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE673 0xE674",
      voicedNative: "य",
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE683 0xE684",
      voicedNative: "",
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
  },

  "Lateral Approx": {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE743 0xE744",
      voicedNative: "ल",
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE763 0xE764",
      voicedNative: "ळ",
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE773 0xE774",
      voicedNative: "",
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE783 0xE784",
      voicedNative: "",
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
  },

  Tap: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE823 0xE824",
      voicedNative: "",
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE843 0xE844",
      voicedNative: "र",
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE863 0xE864",
      voicedNative: "ड़",
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
  },

  Trill: {
    Bilabial: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE913 0xE914",
      voicedNative: "",
    },
    Labiodental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Dental: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Alveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE943 0xE944",
      voicedNative: "",
    },
    Postalveolar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Retroflex: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Palatal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Velar: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Uvular: {
      voiceless: "",
      voicelessNative: "",
      voiced: "0xE993 0xE994",
      voicedNative: "",
    },
    Pharyngeal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
    Glottal: {
      voiceless: "",
      voicelessNative: "",
      voiced: "",
      voicedNative: "",
    },
  },
};

export default Hindi;
